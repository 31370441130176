
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:100,300,400,600);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,800italic,700italic,600italic,400italic,300italic,800,700,600);

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Variables
@import "variables";

$bootstrapXsMax: 767px;
$bootstrapSmMin: 768px;
$bootstrapSmMax: 991px;
$bootstrapMdMin: 992px;
$bootstrapMdMax: 1199px;
$bootstrapLgMin: 1200px;

.text-xs-left { text-align: left; }
.text-xs-right { text-align: right; }
.text-xs-center { text-align: center; }
.text-xs-justify { text-align: justify; }

@media (max-width: $bootstrapXsMax) {

}
@media (min-width: $bootstrapSmMin) and (max-width: $bootstrapSmMax) {
  .text-sm-left { text-align: left; }
  .text-sm-right { text-align: right; }
  .text-sm-center { text-align: center; }
  .text-sm-justify { text-align: justify; }
}
@media (min-width: $bootstrapMdMin) and (max-width: $bootstrapMdMax) {
  .text-md-left { text-align: left; }
  .text-md-right { text-align: right; }
  .text-md-center { text-align: center; }
  .text-md-justify { text-align: justify; }
}
@media (min-width: $bootstrapLgMin) {
  .text-lg-left { text-align: left; }
  .text-lg-right { text-align: right; }
  .text-lg-center { text-align: center; }
  .text-lg-justify { text-align: justify; }
}

.display-table{
  display: table;
  table-layout: fixed;
}

.display-cell{
  display: table-cell;
  vertical-align: middle;
  float: none;
}
.main-section{
  padding:90px 0 110px;
}
/**
 PAYSITE VARIABLES
 */
$paysitePrimaryColor: #eb278d;

html,body
{
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

/**
PAYSITE
 /welcome
 */

$paysiteWelcomeScreenTopPadding: 25px;
$paysiteWelcomeScreenBottomPadding: 0px;
$paysiteWelcomeScreenLineColor: #3c3c3c;
$paysiteWelcomeScreenTermsColor: #717171;

.feed-image {
  max-height: 240px;
}


.paysite-welcome-screen-container {

  text-align: center;
  padding: $paysiteWelcomeScreenTopPadding 0 $paysiteWelcomeScreenBottomPadding;
  width: 100%;
  height: 100%;

  strong {
    color: $paysitePrimaryColor;
  }

  hr {
    background-color: $paysiteWelcomeScreenLineColor;
    height: 1px;
    border: 0;
  }

  .below-line li {
    color: $paysiteWelcomeScreenTermsColor;
  }
}

/**
PAYSITE
 /home
 */
$paysiteNavBackgroundColor: #0c0c0c;
$paysiteNavTitleColor: $paysitePrimaryColor;
$paysiteNavFontSize: 20px;


#paysite-main-nav {

  margin: 0;
  background: $paysiteNavBackgroundColor;

  .navbar-toggle {
    margin-top: 18px;
  }

  .navbar-brand {

    height: auto;

    img {
      height: 40px;
      width: 40px;
    }

    strong {
      font-weight: bold;
      position: relative;
      top: 5px;
      margin-left: 10px;
      color: $paysiteNavTitleColor;
      text-transform: uppercase;
    }

  }

  .navbar-nav {
    font-size: $paysiteNavFontSize;
    line-height: $paysiteNavFontSize;
    margin-top: 10px
  }

  .navbar-nav a {
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
  }

  .navbar-nav a:hover {
 //   text-decoration: underline;
    color: $paysitePrimaryColor;

  }

}

.paysite-header-bar {

  margin: 0;
  width: 100%;
  min-height: 40px;
  background: #eee;

  strong {
    color: #222222;
    font-family: 'Montserrat', sans-serif;
    color: #222222;
    font-size: 16px;
    /* margin: 0 0 5px 0; */
    text-transform: uppercase;
    font-weight: 400;
  }
}

#paysite-feed-container {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 5px;
  background-color: #0f0f0f;

  .panel-heading {
    background-color: #333;
    color: #fff;
    border:none;
  }

  .panel img {
    width: 100%;
    margin: 0;
  }

  .pagination li a {
    text-decoration: none;
    color: #fff;
    background-color: #1f1f1f;
    margin-left: -1px;
    border: 1px solid #101010;
    font-weight: bold;
    font-size: 16px;
  }
  .pagination .active span {
    background-color: $paysitePrimaryColor;
    border: 1px solid $paysitePrimaryColor;
    color: #000;
    font-size: 16px;
    font-weight: bold;
  }
  .pagination li a:hover {
    text-decoration: none;
    background-color: #3f3f3f;
  }

  .pagination .next-btn {
    background-color: $paysitePrimaryColor;
    color: #000;
  }
  .pagination .next-btn:hover {
    background-color: #FF4DB3;
  }
}

#paysite-login-container {

  margin-top: 22px;

  a {
    color:$paysitePrimaryColor;
    transition:all 0.3s ease-in-out;
    -moz-transition:all 0.3s ease-in-out;
    -webkit-transition:all 0.3s ease-in-out;
  }

  a:hover {
    color: #111;
  }

  button {
    background:$paysitePrimaryColor;
    border-radius:4px;
    text-transform:uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight:400;
    border:0;
    transition:all 0.3s ease-in-out;
    -moz-transition:all 0.3s ease-in-out;
    -webkit-transition:all 0.3s ease-in-out;
  }

  button:hover {
    background: #111;
    color: #fff;
  }

  .control-label {
    font-weight: normal;
    color: #555555;
  }

  label {
    font-weight: normal;
    color: #555555;
  }

  .panel {
    background-color: #fafafa;
  }

}

.center_heading {
  text-align: center;
}

.caption {
 // width: 100%;
//  width: 60px;

  padding-right: 5px;
  bottom: 4.5rem;
  position: absolute;
  background:  rgba(0, 0, 0, 0.8);

  h4 {
    padding-top: 5px;
    margin-top: 0;
    margin-bottom: 5px;
  }
  //background: -webkit-linear-gradient(bottom, #000 80%, rgba(0, 0, 0, 0) 80%) repeat scroll 0 0 rgba(0, 0, 0, 0);
  //background: -moz-linear-gradient(bottom, #000 80%, rgba(0, 0, 0, 0) 80%) repeat scroll 0 0 rgba(0, 0, 0, 0);
  //background: -o-linear-gradient(bottom, #000 80%, rgba(0, 0, 0, 0) 80%) repeat scroll 0 0 rgba(0, 0, 0, 0);
  //background: linear-gradient(to top, #000 80%, rgba(0, 0, 0, 0) 80%) repeat scroll 0 0 rgba(0, 0, 0, 0);
}

.panel-title:hover {
  text-underline: none;
 // color: $paysitePrimaryColor;
}

.feed-link {
  text-decoration: none;
}

.hideOverflow
{
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  width:100%;
  display:block;
}

.col-no-padding {
  padding: 10px;
}

.feed-panel {
  border: 0;
  box-shadow: none;
  background-color: transparent;
  margin-bottom: 0;
  position: relative;
}

.feed-panel-body {
  padding-bottom: 0;
  padding: 10px;
  margin: 0;
}

.feed-item-title {
  
  color: #fff;
  font-size: 15px;
  margin-top:5px;
  width:100%;
  font-weight: bold;

  line-height: 1.3em;
  position: relative;
  max-height: 2.6em;
  
}

.feed-item-title:hover {
  text-decoration: underline;
}

.feed-item-duration {
  font-size: 12px;
  color: #fff;

  strong {
    color: $paysitePrimaryColor;
  }
}

.feed-link {
  cursor: pointer;
}

.fade-transition {
  transition: all 0.8s ease;
  overflow: hidden;
  visibility: visible;
  opacity: 1;
  position: absolute;
}
.fade-enter, .fade-leave {
  opacity: 0;
  visibility: hidden;
}

.copyright a, .credits a {
  color: $paysitePrimaryColor;
}

#sendmessage {
  color: $paysitePrimaryColor;
  border:1px solid $paysitePrimaryColor;
  display:none;
  text-align:center;
  padding:15px;
  font-weight:600;
  margin-bottom:15px;
}

.res-nav_click:hover, .res-nav_click:active, .res-nav_click:focus {
  color: $paysitePrimaryColor !important;
}

.input-text:focus {
  border: 1px solid $paysitePrimaryColor;
  outline:0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(124, 197, 118, 0.3);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(124, 197, 118, 0.3);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(124, 197, 118, 0.3);
}

.input-btn{
  width:175px;
  height:50px;
  background:$paysitePrimaryColor;
  border-radius:4px;
  color:#ffffff;
  font-size:14px;
  text-transform:uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight:400;
  border:0px;
  transition:all 0.3s ease-in-out;
  -moz-transition:all 0.3s ease-in-out;
  -webkit-transition:all 0.3s ease-in-out;
}

.main-nav li a:hover{
  text-decoration:none;
  color: $paysitePrimaryColor;
}

.link{
  padding:15px 35px;
  border: 1px solid $paysitePrimaryColor;
  background: $paysitePrimaryColor;
  color:#fff !important;
  font-size:16px;
  font-weight:400;
  font-family: 'Montserrat', sans-serif;
  display:inline-block;
  border-radius:3px;
  text-transform:uppercase;
  line-height:25px;
  margin-bottom:20px;
  transition:all 0.3s ease-in-out;
  -moz-transition:all 0.3s ease-in-out;
  -webkit-transition:all 0.3s ease-in-out;
}

.link:hover {
  text-decoration:none;
  color:$paysitePrimaryColor !important;
  border: 1px solid #fff;
  background:#fff;
}

.link:active, .link:focus {
  background: $paysitePrimaryColor;
  text-decoration:none;
  color:#fff !important;
  border: 1px solid #fff;
}

.paysite-nav-row {
  padding-top: 22px;
  padding-bottom: 22px;

  ul {
    margin-left:auto;
    margin-right:auto;

    li {
      a {
        text-decoration: none;
        color: #fff;
        background-color: #1f1f1f;
        margin-left: -1px;
        border: 1px solid #101010;
        font-weight: 400;
        font-size: 16px;
        text-transform: uppercase;
        cursor: pointer;
      }
      a:hover {
        text-decoration: none;
        background-color: #3f3f3f;
      }
    }

    li.active {
      a {
        background-color: $paysitePrimaryColor;
        color: #000;
        font-size: 16px;
        font-weight: 700;
        cursor: default;
      }
      a:hover {
        color: #000;
        text-decoration: none;
        background-color: #FF4DB3;
        cursor: default;
      }
    }

  }

}